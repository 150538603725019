import React from "react";
import { StaticQuery, graphql } from "gatsby";

const StandAloneComunicare = () => (
  <>
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(
            frontmatter: { path: { eq: "/comunicareelectronica" } }
          ) {
            html
          }
        }
      `}
      render={(data) => {
        const { html } = data.markdownRemark;
        return (
          <section
            className="section--markdown custom-md-images"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        );
      }}
    />
  </>
);

export default StandAloneComunicare;
